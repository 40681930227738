// eslint-disable-next-line

import axios from 'axios';
import { decodeJWT } from '../Utilities/Utils';
import { clearAndLogout } from './CommonServices';
import { ManageLocalStorage } from './LocalStorage';

const AxiosAPI = async (method, path, request = {}, auth = false) => {
  // eslint-disable-next-line
    try {

    let Endpoint = 'http://192.168.1.18:90';
    switch (process.env.REACT_APP_ENV) {
      case 'dev':
        Endpoint = 'http://192.168.1.18:90';
        break;
      case 'qa':
        Endpoint = 'http://192.168.1.18:85/api';
        break;
      case 'stage':
        Endpoint = 'http://stage-api.bustracks.com';
        break;
      case 'prod':
        Endpoint = 'https://app.bustracks.com/api';
        break;
      default:
        break;
    }
    const url = `${Endpoint}${path}`;
    const header = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    if (auth) {
      const token = ManageLocalStorage.get('Auth', true);
      if (!token) {
        clearAndLogout();
      }
      header.headers.Authorization = `Bearer ${token}`;
      const { exp } = decodeJWT(token);
      if (Date.now() > exp * 1000 - 60000) {
        clearAndLogout();
        return;
      }
    }
    switch (method) {
      case 'get':
        return axios
          .get(url, header)
          .then(result => result)
          .catch(e => {
            if (e.response?.status === 401) {
              clearAndLogout();
            }
            return e;
          });
      case 'post':
        return axios
          .post(url, request, header)
          .then(result => result)
          .catch(e => {
            if (e.response?.status === 401 && !url.includes('User/Authenticate')) {
              clearAndLogout();
            }
            return e;
          });
      case 'put':
        return axios
          .put(url, request, header)
          .then(result => result)
          .catch(e => {
            if (e.response?.status === 401) {
              clearAndLogout();
            }
            return e;
          });
      case 'delete':
        return axios
          .delete(url, header)
          .then(result => result)
          .catch(e => {
            if (e.response?.status === 401) {
              clearAndLogout();
            }
            return e;
          });
      default:
        break;
    }
  } catch (error) {
    return error;
  }
};

export default AxiosAPI;
