import _cloneDeep from 'lodash/cloneDeep';
export const getArrObj = array => array.map(e => ({ ValueField: e, TextField: e }));

export const validateMaxLen = (len, isreqd) => {
  const validationSchema = {};
  if (isreqd) {
    validationSchema.required = 'Required';
    validationSchema.validate = { required: val => val.trim() !== '' || 'Required' };
  }
  validationSchema.maxLength = { value: len, message: `Max ${len} characters` };
  return validationSchema;
};

export const validateFloatLen = (int, dec, isreqd) => {
  const validationSchema = { validate: {} };
  if (isreqd) {
    validationSchema.required = 'Required';
    validationSchema.validate.required = val => val.trim() !== '' || 'Required';
  }
  if (dec === 0) {
    return { ...validateMaxLen(int, isreqd), pattern: { value: /^\d+$/, message: 'Only Whole numbers' } };
  }
  validationSchema.validate.integer = val => /^(\s*|\d+)$/.exec(val.split('.')[0]) || 'Numbers only';
  validationSchema.validate.decimal = val => !val.split('.')[1] || /^(\s*|\d+)$/.exec(val.split('.')[1]) || 'Numbers only';

  validationSchema.validate.maxInt = val => val.split('.')[0].length < int + 1 || `Max ${int} whole digits allowed`;
  validationSchema.validate.maxDec = val => !val.split('.')[1] || val.split('.')[1].length < dec + 1 || `Only ${dec} decimals allowed`;
  return validationSchema;
};

export const decodeJWT = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const getTimeString = timeObj => (timeObj.Meridiem ? `${timeObj.Hour12}:${timeObj.Minute.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} ${timeObj.Meridiem}` : null);

export const convertTime24to12 = timeString => {
  var H = +timeString.substr(0, 2);
  var h = H % 12 || 12;
  var ampm = H < 12 || H === 24 ? 'am' : 'pm';
  return { Formatted12: h + timeString.substr(2, 3), Meridiem: ampm };
};

export const convertTime12to24 = time12h => {
  const { Formatted12, Meridiem } = time12h;

  let [hours, minutes] = Formatted12.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (Meridiem === 'pm') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};
export const ObjDiff = function (obj1, obj2) {
  // Make sure an object to compare is provided
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return obj1;
  }
  var diffs = {};
  /**
   * Check if two arrays are equal
   * @param  {Array}   arr1 The first array
   * @param  {Array}   arr2 The second array
   * @return {Boolean}      If true, both arrays are equal
   */
  var arraysMatch = function (arr1, arr2) {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all items exist and are in the same order
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    // Otherwise, return true
    return true;
  };

  /**
   * Compare two items and push non-matches to object
   * @param  {*}      item1 The first item
   * @param  {*}      item2 The second item
   * @param  {String} key   The key in our object
   */
  var compare = function (item1, item2, key) {
    // Get the object type
    var type1 = Object.prototype.toString.call(item1);
    var type2 = Object.prototype.toString.call(item2);

    // If type2 is undefined it has been removed
    if (type2 === '[object Undefined]') {
      diffs[key] = null;
      return;
    }

    // If items are different types
    if (type1 !== type2) {
      diffs[key] = item2;
      return;
    }

    // If an object, compare recursively
    if (type1 === '[object Object]') {
      var objDiff = ObjDiff(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }

    // If an array, compare
    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2;
      }
      return;
    }

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2;
      }
    } else {
      if (item1 !== item2) {
        diffs[key] = item2;
      }
    }
  };
  // Compare our objects
  // Loop through the first object
  Object.keys(obj1).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      compare(obj1[key], obj2[key], key);
    }
  });
  // Loop through the second object and find missing items
  Object.keys(obj1).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (!obj1[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key];
      }
    }
  });
  // Return the object of differences
  return diffs;
};
export const mergeArray = (path, arr1 = [], arr2 = []) => arr1.map(t1 => ({ ...t1, ...arr2.find(t2 => t2[path] === t1[path]) }));

export const groupArray = (path, arr = []) =>
  arr.reduce(function (r, a) {
    r[a[path]] = r[a[path]] || [];
    r[a[path]].push(a);
    return r;
  }, Object.create(null));

export const nullifyObjVal = obj => {
  const newObj = _cloneDeep(obj);
  Object.keys(newObj).forEach(key => {
    newObj[key] = typeof newObj[key] === 'boolean' || newObj[key] ? newObj[key] : null;
  });
  return newObj;
};
export const getSelectFieldVal = (obj, keys) => {
  const newObj = _cloneDeep(obj);
  keys.forEach(el => {
    newObj[el] = typeof newObj[el] === 'number' ? newObj[el] : newObj[el]?.ValueField || null;
  });
};

export const getDateString = (obj, keys) => {
  const newObj = _cloneDeep(obj);
  keys.forEach(el => {
    newObj[el] = typeof newObj[el] === 'number' ? newObj[el] : newObj[el]?.ValueField || null;
  });
};
export const nullifyAndFormatObjVal = (obj, selectfields = [], dates = []) => {
  const newObj = _cloneDeep(obj);
  Object.keys(newObj).forEach(key => {
    newObj[key] = typeof newObj[key] === 'boolean' || newObj[key] ? newObj[key] : null;
  });

  selectfields.forEach(el => {
    newObj[el] = newObj[el]?.ValueField ?? null;
  });
  dates.forEach(el => {
    newObj[el] = newObj[el] instanceof Date ? newObj[el].toLocaleDateString('en-US') : null;
  });
  return newObj;
};

export const getInitials = string => string[0]?.toUpperCase() || '';

export const isInactive = userDetail => !!userDetail?.UserId && !!userDetail?.ExpireDate && new Date(userDetail?.ExpireDate)?.getTime() - Date.now() < 60000;

export const getGradeDivision = grade => {
  if (['PKA', 'PKP', 'PKF', 'KA', 'KP', 'KF'].includes(grade)) {
    return 'Kindergarden';
  } else if (['1', '2', '3', '4', '5'].includes(grade)) {
    return 'Elementary';
  } else if (['6', '7', '8', '9', '10', '11', '12'].includes(grade)) {
    return 'Secondary';
  } else {
    return '';
  }
};

export const floatToFixed = (num, fixed = 1) => (isNaN(parseFloat(num)) ? '' : parseFloat(num).toFixed(fixed));
