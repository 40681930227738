import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { GlobalContext } from '../Services/ContextAPI';

const CustomRouteGuard = ({ xComponent: Component, ...xProps }) => {
  const { globalState } = React.useContext(GlobalContext);
  const { pathname: pathName } = useLocation();
  return (
    <Route
      {...xProps}
      render={routeParams => {
        if (globalState.isLoggedIn) {
          if (pathName === '/login') {
            return <Redirect to="/dashboard" />;
          }
          if ([2, 4].includes(globalState.userDetails.UserType) && ['/district/list'].includes(pathName)) {
            return <Redirect to="/district/detail" />;
          }
          if (![1, 5].includes(globalState.userDetails.UserType) && ['/notification'].includes(pathName)) {
            return <Redirect to="/dashboard" />;
          }
          if (
            ([3, 6].includes(globalState.userDetails.UserType) || globalState.isInActiveUser) &&
            [
              '/district/list',
              '/district/detail',
              '/user/list',
              '/calendar/list',
              '/calendar/detail',
              '/vehicle/list',
              '/vehicle/detail',
              '/contract/list',
              '/contract/detail',
              '/staff/list',
              '/staff/detail',
              '/house/list',
              '/stop/list',
              '/mileage',
              '/program/list',
              '/program/detail'
            ].includes(pathName)
          ) {
            return <Redirect to="/dashboard" />;
          }
          return <Component {...routeParams} key={routeParams.match.url} />;
        }
        if (pathName === '/login') {
          return <Component {...routeParams} key={routeParams.match.url} />;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};
CustomRouteGuard.propTypes = {
  xComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};
export default CustomRouteGuard;
