import React from 'react';
import { useFormContext } from 'react-hook-form';

export const ConnectForm = ({ children }) => {
  const methods = useFormContext();

  return children({ ...methods });
};

export const GlobalContext = React.createContext(null);
GlobalContext.displayName = 'GlobalContext';

export const GlobalDispatchContext = React.createContext(null);
GlobalDispatchContext.displayName = 'GlobalDispatchContext';

export const DashBoardContext = React.createContext(null);
DashBoardContext.displayName = 'DashBoardContext';

export const StudentListContext = React.createContext(null);
StudentListContext.displayName = 'StudentListContext';

export const StudentDetailContext = React.createContext(null);
StudentDetailContext.displayName = 'StudentDetailContext';

export const UserListContext = React.createContext(null);
UserListContext.displayName = 'UserListContext';

export const SchoolListContext = React.createContext(null);
SchoolListContext.displayName = 'SchoolListContext';

export const SchoolDistrictContext = React.createContext(null);
SchoolDistrictContext.displayName = 'SchoolDistrictContext';

export const CalendarContext = React.createContext(null);
CalendarContext.displayName = 'CalendarContext';

export const VehicleContext = React.createContext(null);
VehicleContext.displayName = 'VehicleContext';

export const ContractContext = React.createContext(null);
ContractContext.displayName = 'ContractContext';

export const StaffContext = React.createContext(null);
StaffContext.displayName = 'StaffContext';

export const BusRunsContext = React.createContext(null);
BusRunsContext.displayName = 'BusRunsContext';

export const ProgramContext = React.createContext(null);
ProgramContext.displayName = 'ProgramContext';

export const ToolsContext = React.createContext(null);
ToolsContext.displayName = 'ToolsContext';

export const MileageContext = React.createContext(null);
MileageContext.displayName = 'MileageContext';

export const ReportContext = React.createContext(null);
ReportContext.displayName = 'ReportContext';

export const HousesContext = React.createContext(null);
HousesContext.displayName = 'HousesContext';

export const StopContext = React.createContext(null);
StopContext.displayName = 'StopContext';

export const MoveGroupContext = React.createContext(null);
MoveGroupContext.displayName = 'MoveGroupContext';

export const NotificationContext = React.createContext(null);
NotificationContext.displayName = 'NotificationContext';
