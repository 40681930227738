import React from 'react';
import PropTypes from 'prop-types';
import success from './../../Assets/images/sucess.png';
import error from './../../Assets/images/error.png';
import info from './../../Assets/images/info.png';

const showToast = (type, message) => {
  const id = Math.floor(Math.random() * 101 + 1);
  let toastProperties;
  switch (type) {
    case 'success':
      toastProperties = {
        id,
        title: 'Danger',
        description: message,
        backgroundColor: '#5cb85c',
        icon: success
      };
      break;
    case 'info':
      toastProperties = {
        id,
        title: 'Info',
        description: message,
        backgroundColor: '#5bc0de',
        icon: info
      };
      break;
    case 'error':
      toastProperties = {
        id,
        title: 'Danger',
        description: message,
        backgroundColor: '#d9534f',
        icon: error
      };
      break;
    default:
      break;
  }
  return toastProperties;
};
let openSnackbarFn;
const position = 'top-right';
const autoDelete = true;

const Toast = () => {
  const [list, setList] = React.useState([]);

  const openSnackbarHandler = (type, message, timeout) => {
    const params = showToast(type, message);
    list.push(params);
    setList([...list]);
    setTimeout(() => {
      if (autoDelete && list.length) {
        deleteToast(params.id);
      }
    }, timeout || 3000);
  };

  React.useEffect(() => {
    openSnackbarFn = openSnackbarHandler;
    return () => {};
    // eslint-disable-next-line
    }, [])

  const deleteToast = id => {
    const listItemIndex = list.findIndex(e => e.id === id);
    list.splice(listItemIndex, 1);
    setList([...list]);
  };

  return (
    <>
      <div className={`notification-container ${position}`}>
        {list.map((toast, i) => (
          <div key={i} className={`notification toast ${position}`} style={{ backgroundColor: toast.backgroundColor }}>
            <button className="notificationClose" onClick={() => deleteToast(toast.id)}></button>
            <div className="notification-image">
              <img src={toast.icon} alt="" />
            </div>
            <div>
              <span className="notification-title">{toast.description}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

Toast.propTypes = {
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number
};

export default Toast;

export const openSnackbar = (message, variant, timeout) => {
  openSnackbarFn(message, variant, timeout);
};
