import { LIST_FAILED, LIST_SUCCESS } from '../Utilities/Constants';
import { miscAPIEndpoints } from './APIEndpoints';
import AxiosAPI from './AxiosAPI';
import { ManageLocalStorage } from './LocalStorage';

export const login1 = body => {
  if (body.username === 'nobody') {
    return new Promise((resolve, reject) =>
      setTimeout(
        () =>
          reject({
            data: null,
            status: false,
            message: 'Internal Server Error'
          }),
        2000
      )
    );
  } else {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            data: {
              UserId: 2,
              FirstName: 'John',
              LastName: 'Paul',
              EmailId: 'john@gmail.com',
              PhoneNumber: '221',
              UserType: 1,
              CompanyId: 2,
              CompanyName: 'Great Valley',
              SchoolId: 2,
              CompanyYearId: 5
            },
            status: true,
            message: null
          }),
        2000
      )
    );
  }
};

export const getGradeList = () => AxiosAPI('get', miscAPIEndpoints.gradeList(), {}, true);
export const getSchoolList = id => AxiosAPI('get', miscAPIEndpoints.schoolList(id), {}, true);
export const getSchoolDistrictList = () => AxiosAPI('get', miscAPIEndpoints.schoolDistrictList(), {}, true);
export const getAcademicYrList = id => AxiosAPI('get', miscAPIEndpoints.academicYrList(id), {}, true);
export const getUserTypeList = id => AxiosAPI('get', miscAPIEndpoints.userTypeList(id), {}, true);
export const getSchoolYrList = () => AxiosAPI('get', miscAPIEndpoints.schoolYrList(), {}, true);
export const getDriverList = id => AxiosAPI('get', miscAPIEndpoints.getDriverList(id), {}, true);
export const contractorNameList = (id, isreport) => AxiosAPI('get', miscAPIEndpoints.contractorNameList(id, isreport), {}, true);
export const getRunsList = id => AxiosAPI('get', miscAPIEndpoints.getRunsList(id), {}, true);
export const getVehicles = (id, isreport) => AxiosAPI('get', miscAPIEndpoints.getVehicleList(id, isreport), {}, true);
export const getStopTypeList = () => AxiosAPI('get', miscAPIEndpoints.stopTypeList(), {}, true);
export const resetStopCount = (CompanyYearId) => AxiosAPI('post', miscAPIEndpoints.resetStopCount(CompanyYearId), {}, true);

export const getNotifications = (companyId, companyYrId) => AxiosAPI('get', miscAPIEndpoints.getNotifications(companyId, companyYrId), {}, true);

export const listAPI = (companyID, userTypeId, companyYrId) => {
  let promiseList = [];
  const gradeListPromise = getGradeList();
  const schoolListPromise = getSchoolList(companyYrId);
  const districtListPromise = getSchoolDistrictList();
  const userTypeListPromise = getUserTypeList(userTypeId);
  const academicYrListPromise = getAcademicYrList(companyID);
  promiseList = [gradeListPromise, schoolListPromise, districtListPromise, userTypeListPromise, academicYrListPromise];
  return Promise.all(promiseList)
    .then(response => response)
    .catch(e => e);
};
export const getListData = (userDetails, dispatch, openSnackbar) => {
  listAPI(userDetails.CompanyId, userDetails.UserType, userDetails.CompanyYearId)
    .then(res => {
      if (res[0].data?.Data) {
        const payload = {
          gradeList: res[0].data?.Data || [],
          schoolList: res[1].data?.Data || [],
          districtList: res[2].data?.Data || [],
          userTypeList: res[3].data?.Data || [],
          academicYrList: res[4].data?.Data || []
        };
        dispatch({ type: LIST_SUCCESS, payload: payload });
      } else {
        dispatch({ type: LIST_FAILED });
        openSnackbar('error', res.message || 'Failed to Get List !!');
      }
    })
    .catch(e => {
      dispatch({ type: LIST_FAILED });
      openSnackbar('error', e?.message || 'Failed to Get List !!');
    });
};
export const getListObjectSelect = list => {
  const listObj = [];
  list.map(item => {
    if (typeof item === 'string' || item instanceof String) {
      listObj.push({ value: item, label: item });
    } else if (Object.prototype.hasOwnProperty.call(item, 'id') && Object.prototype.hasOwnProperty.call(item, 'name')) {
      listObj.push({ value: item.id, label: item.name });
    } else if (typeof item === 'number' || item instanceof Number) {
      listObj.push({ value: item.toString(), label: item.toString() });
    } else {
      listObj.push(item);
    }
    return true;
  });
  return listObj;
};

export const getRole = UserType => {
  switch (UserType) {
    case 1:
      return 'System Administrator';
    case 2:
      return 'School District User';
    case 3:
      return 'School User';
    case 4:
      return 'Intermediate User';
    case 5:
      return 'Super Admin';
    case 6:
      return 'School User';
    default:
      return '';
  }
};

export const userTypeListGlobal = [
  { TextField: 'System Administrator', ValueField: 1 },
  { TextField: 'School District User', ValueField: 2 },
  { TextField: 'School User', ValueField: 3 },
  { TextField: 'Intermediate User', ValueField: 4 },
  { TextField: 'Super Admin', ValueField: 5 },
  { TextField: 'School User', ValueField: 6 }
];

export const getListPayload = (getby, params, payload) => {
  switch (getby) {
    case 'clear':
      return { ...params };
    case 'sort':
    case 'paginate':
      return { ...payload, ...params };
    case 'search':
      return { ...payload, ...params, selectedPage: 1 };
    default:
      return payload;
  }
};

export const clearAndLogout = () => {
  ManageLocalStorage.clear();
  window.location.replace('/dashboard');
};
export const isValidDate = d => {
  const date = new Date(d);
  return date instanceof Date && !isNaN(date.getMilliseconds());
};
export const getDateDiff = (date1, date2 = Date.now()) => {
  const diff = new Date(date1)?.getTime() - new Date(date2).getTime();
  const days = diff / (1000 * 3600 * 24);
  return days;
};
export const getNotificationList = items => {
  const notificationArr = (items?.Notifications || []).map(el => ({
    item: 'Message',
    heading: 'System Notification',
    content: <> {el?.Notification} </>
  }));

  if (isValidDate(items?.ExpireDate)) {
    const daysLeft = getDateDiff(items?.ExpireDate);
    if (daysLeft <= 30) {
      notificationArr.push({
        item: 'License',
        content:
          daysLeft <= 0 ? (
            <>Your License Expired on {new Date(items?.ExpireDate).toLocaleString()}. Renew to continue services</>
          ) : (
            <>
              Your License will expire in <strong>{Math.floor(daysLeft)} days</strong>. Renew before {new Date(items?.ExpireDate).toLocaleString()} to continue services
            </>
          ),
        heading: daysLeft <= 0 ? 'License Expired' : 'License about to expire'
      });
    }
  }
  if (items?.IsStaffExpired) {
    notificationArr.push({
      item: 'StaffLicense',
      heading: 'Driver License Expired',
      content: <> CDL License of one or more Drivers have expired</>
    });
  }
  return notificationArr;
};
