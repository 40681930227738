import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../../Shared/Components/Footer';
import { GlobalContext } from '../../Services/ContextAPI';
import Header from '../../Shared/Components/Header';
import Loader from '../../Shared/Components/Loader';
import LeftMenu from '../../Shared/Components/LeftMenu';
import Toast from '../../Shared/Components/Toast';
import { clearAndLogout } from '../../Services/CommonServices';
import { ManageLocalStorage } from '../../Services/LocalStorage';
import { ObjDiff } from '../../Utilities/Utils';

const MainWrapper = props => {
  const { children } = props;

  const { globalState } = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (globalState.isLoggedIn) {
      var interval = setInterval(() => {
        const userDetails = ManageLocalStorage.get('userDetails');
        const change = ObjDiff(userDetails, globalState.userDetails);
        if (Object.keys(change).length) {
          clearAndLogout();
        }
      }, 2000);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [globalState.isLoggedIn, globalState.userDetails]);

  return (
    <>
      <Loader />
      <Toast />
      {globalState && globalState.isLoggedIn ? (
        <div className="wrapper">
          <Header />
          <div className="mainContent">
            <LeftMenu componentData={globalState.currentComponent} UserType={globalState.userDetails.UserType} isInActiveUser={globalState.isInActiveUser} />
            {children}
          </div>
          <Footer />
        </div>
      ) : (
        children
      )}
    </>
  );
};
MainWrapper.propTypes = {
  children: PropTypes.object
};
export default MainWrapper;
