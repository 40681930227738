import React from 'react';
import PropTypes from 'prop-types';
import { GlobalContext, GlobalDispatchContext } from '../Services/ContextAPI';
import { ManageLocalStorage } from '../Services/LocalStorage';
import rootReducer from './RootReducer';
import { isInactive } from '../Utilities/Utils';
const ContextStore = ({ children }) => {
  const userDetails = ManageLocalStorage.get('userDetails');
  const listDataIntial = { academicYrList: [], districtList: [], gradeList: [], schoolList: [], userTypeList: [] };
  const initialState = {
    loginState: {
      userDetails: userDetails || {},
      isLoggedIn: !!userDetails.UserId,
      isInActiveUser: isInactive(userDetails)
    },
    componentState: {
      componentData: '',
      currentComponent: ''
    },
    listDataState: listDataIntial,
    loadingState: {
      isLoading: false
    }
  };

  const [globalState, dispatch] = React.useReducer(rootReducer, initialState);

  const { loginState, componentState, loadingState, listDataState } = globalState;
  const states = {
    globalState: { ...loginState, ...componentState, loadingState, listDataState }
  };
  return (
    <GlobalContext.Provider value={states}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalContext.Provider>
  );
};
export default ContextStore;
ContextStore.propTypes = {
  children: PropTypes.object
};
