import { LOGIN_SUCCESS, LIST_SUCCESS, CURRENT_COMPONENT, COMPONENT_DATA, LOGOUT, LOGIN_FAILED } from '../Utilities/Constants';
import { ManageLocalStorage } from '../Services/LocalStorage';
import { selectorList } from '../Utilities/Constants';
import { isInactive } from '../Utilities/Utils';

const initialState = {
  loginState: {
    userDetails: ManageLocalStorage.get('userDetails') || {},
    isLoggedIn: !!ManageLocalStorage.get('userDetails'),
    isInActiveUser: true
  },
  componentState: {
    componentData: '',
    currentComponent: ''
  },
  listDataState: {},
  loadingState: {
    isLoading: false
  }
};
const loadingSelector = state => {
  const loaderReq = Object.keys(state).filter(e => selectorList.includes(e));
  const istrue = loaderReq.find(e => !!state[e]);
  return !!istrue;
};

const rootReducer = (state = initialState, action = {}) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILED)/.exec(type);
  let loader = { ...state.loadingState };
  if (matches) {
    const [, requestName, requestState] = matches;
    loader = {
      ...loader,
      [requestName]: requestState === 'REQUEST'
    };
  }
  const loadingState = {
    ...loader,
    isLoading: loadingSelector(loader)
  };
  switch (action.type) {
    case LOGIN_SUCCESS:
      ManageLocalStorage.set('userDetails', action.payload);
      return { ...state, loadingState, loginState: { ...state.loginState, isLoggedIn: true, userDetails: action.payload, isInActiveUser: isInactive(action.payload) } };
    case LOGOUT:
    case LOGIN_FAILED:
      return {
        ...state,
        loadingState,
        loginState: {
          isLoggedIn: false,
          userDetails: {},
          isInActiveUser: true
        },
        listDataState: {}
      };
    case CURRENT_COMPONENT:
      return { ...state, componentState: { ...state.componentState, currentComponent: action.payload } };
    case COMPONENT_DATA:
      return { ...state, componentState: { ...state.componentState, componentData: action.payload } };
    case LIST_SUCCESS:
      return { ...state, loadingState, listDataState: { ...state.listDataState, ...action.payload } };
    default:
      if (!matches) {
        return state;
      }
      return { ...state, loadingState };
  }
};

export default rootReducer;
