import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RootRoutes from './Core/RootRoutes';
import * as serviceWorker from './serviceWorker';
import './Assets/scss/vendor/bootstrap/bootstrap.min.css';
import './Assets/scss/vendor/fonts/fonts.css';
import './Assets/scss/components/main.scss';

ReactDOM.render(
  // <React.StrictMode>
  <RootRoutes />,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
