import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import PropTypes from 'prop-types';
import notificationIcon from '../../Assets/images/notification.png';
import React from 'react';
const popover = notifications =>
  notifications.length > 0 ? (
    <Popover id="popover-basic">
      <Popover.Title as="h3">New Notifications ({notifications.length}) </Popover.Title>

      {notifications.map((el, i) => (
        <React.Fragment key={i}>
          <Popover.Title as="h5">{el?.heading}</Popover.Title>
          <Popover.Content> {el?.content} </Popover.Content>
        </React.Fragment>
      ))}
    </Popover>
  ) : (
    <Popover id="popover-basic">
      <Popover.Title as="h3">No Notification </Popover.Title>
      <Popover.Content> You dont have any new Notifications </Popover.Content>
    </Popover>
  );
const NotificationPanel = ({ notifications }) => (
  <OverlayTrigger trigger="click" placement="bottom" overlay={popover(notifications)} rootClose>
    <button type="button" className="btn position-relative">
      <img src={notificationIcon} alt="" height="25px" width="20px" />
      <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2">
        <span className="visually-hidden">{notifications.length}</span>
      </span>
    </button>
  </OverlayTrigger>
);

NotificationPanel.propTypes = {
  notifications: PropTypes.array
};
export default NotificationPanel;
