import React from 'react';
import { Switch, Redirect, BrowserRouter } from 'react-router-dom';
import MainWrapper from '../Modules/MainWrapper/MainWrapper';
import ErrorBoundary from '../Utilities/ErrorBoundary';
import RootRouteGuard from './RootRouteGuard';
import ContextStore from './ContextStore';
import { lazyLoadWithFailSafe } from '../Utilities/LazyLoader';
import CustomRouteGuard from './CustomRouteGuards';
import { CalendarRoutes, DashBoardRoutes, ReportRoutes, SchoolDistrictRoutes, SchoolRoutes, StudentRoutes, ToolsRoutes, UserRoutes } from './CustomRoutes';
const LoginContainer = lazyLoadWithFailSafe(() => import('../Modules/Login/LoginContainer'));
const VehicleListContainer = lazyLoadWithFailSafe(() => import('../Modules/Vehicles/VehicleList/VehicleListContainer'));
const VehicleDetailContainer = lazyLoadWithFailSafe(() => import('../Modules/Vehicles/VehicleDetail/VehicleDetailContainer'));
const ContractListContainer = lazyLoadWithFailSafe(() => import('../Modules/Contract/ContractList/ContractListContainer'));
const ContractDetailContainer = lazyLoadWithFailSafe(() => import('../Modules/Contract/ContractDetail/ContractDetailContainer'));
const StaffListContainer = lazyLoadWithFailSafe(() => import('../Modules/Staff/StaffList/StaffListContainer'));
const StaffDetailContainer = lazyLoadWithFailSafe(() => import('../Modules/Staff/StaffDetail/StaffDetailContainer'));
const MileageContainer = lazyLoadWithFailSafe(() => import('../Modules/Mileage/MileageContainer'));
const RunAssignmentContainer = lazyLoadWithFailSafe(() => import('../Modules/BusRuns/RunAssignment/RunAssignmentContainer'));
const RunListContainer = lazyLoadWithFailSafe(() => import('../Modules/BusRuns/RunList/RunListContainer'));
const RunDetailContainer = lazyLoadWithFailSafe(() => import('../Modules/BusRuns/RunDetail/RunDetailContainer'));

const ProgramListContainer = lazyLoadWithFailSafe(() => import('../Modules/ProgramContacts/ProgramList/ProgramListContainer'));
const ProgramDetailContainer = lazyLoadWithFailSafe(() => import('../Modules/ProgramContacts/ProgramDetail/ProgramDetailContainer'));

const HouseListContainer = lazyLoadWithFailSafe(() => import('../Modules/House/HouseListContainer'));
const StopListContainer = lazyLoadWithFailSafe(() => import('../Modules/Stop/StopListContainer'));
const MoveGroupsContainer = lazyLoadWithFailSafe(() => import('../Modules/MoveGroups/MoveGroupsContainer'));

const NotificationListContainer = lazyLoadWithFailSafe(() => import('../Modules/NotificationList/NotificationListContainer'));

const RootRoutes = () => (
  <BrowserRouter>
    <ContextStore>
      <ErrorBoundary>
        <MainWrapper>
          <React.Suspense
            fallback={
              <>
                <div className="linear-activity">
                  <div className="indeterminate"></div>
                </div>
                <div className="loader_wrap loader"></div>
              </>
            }
          >
            <Switch>
              <RootRouteGuard path="/login" xComponent={LoginContainer} />
              <RootRouteGuard path="/dashboard" xComponent={DashBoardRoutes} />
              <CustomRouteGuard path="/student" xComponent={StudentRoutes} />
              <CustomRouteGuard path="/school" xComponent={SchoolRoutes} />
              <CustomRouteGuard path="/district" xComponent={SchoolDistrictRoutes} />
              <CustomRouteGuard path="/user" xComponent={UserRoutes} />
              <CustomRouteGuard path="/calendar" xComponent={CalendarRoutes} />
              <CustomRouteGuard path="/vehicle/list" xComponent={VehicleListContainer} />
              <CustomRouteGuard path="/vehicle/detail" xComponent={VehicleDetailContainer} />
              <CustomRouteGuard path="/contract/list" xComponent={ContractListContainer} />
              <CustomRouteGuard path="/contract/detail" xComponent={ContractDetailContainer} />
              <CustomRouteGuard path="/staff/list" xComponent={StaffListContainer} />
              <CustomRouteGuard path="/staff/detail" xComponent={StaffDetailContainer} />
              <CustomRouteGuard path="/program/list" xComponent={ProgramListContainer} />
              <CustomRouteGuard path="/program/detail" xComponent={ProgramDetailContainer} />
              <CustomRouteGuard path="/runs/runassignment" xComponent={RunAssignmentContainer} />
              <CustomRouteGuard path="/runs/list" xComponent={RunListContainer} />
              <CustomRouteGuard path="/runs/detail" xComponent={RunDetailContainer} />
              <CustomRouteGuard path="/mileage" xComponent={MileageContainer} />
              <CustomRouteGuard path="/report" xComponent={ReportRoutes} />
              <CustomRouteGuard path="/tools/board" xComponent={ToolsRoutes} />
              <CustomRouteGuard path="/house/list" xComponent={HouseListContainer} />
              <CustomRouteGuard path="/stop/list" xComponent={StopListContainer} />
              <CustomRouteGuard path="/movegroups" xComponent={MoveGroupsContainer} />
              <CustomRouteGuard path="/notification" xComponent={NotificationListContainer} />
              <Redirect from="/student/*" to="/student/list" push />
              <Redirect from="/school/*" to="/school/list" push />
              <Redirect from="/district/*" to="/district/list" push />
              <Redirect from="*" to="/login" push />
            </Switch>
          </React.Suspense>
        </MainWrapper>
      </ErrorBoundary>
    </ContextStore>
  </BrowserRouter>
);

export default RootRoutes;
