import React from 'react';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';
import { getAcademicYrList, getGradeList, getSchoolDistrictList, getSchoolList, getUserTypeList } from '../Services/CommonServices';
import { GlobalContext, GlobalDispatchContext } from '../Services/ContextAPI';
import { openSnackbar } from '../Shared/Components/Toast';
import { LIST_FAILED, LIST_REQUEST, LIST_SUCCESS } from '../Utilities/Constants';
import { lazyLoadWithFailSafe } from '../Utilities/LazyLoader';

const DashboardContainer = lazyLoadWithFailSafe(() => import('../Modules/Dashboard/DashboardContainer'));

const SchoolListContainer = lazyLoadWithFailSafe(() => import('../Modules/School/SchoolList/SchoolListContainer'));
const SchoolDetailContainer = lazyLoadWithFailSafe(() => import('../Modules/School/SchoolDetail/SchoolDetailContainer'));

const SchoolDistrictListContainer = lazyLoadWithFailSafe(() => import('../Modules/SchoolDistrict/SchoolDistrictList/SchoolDistrictListContainer'));
const SchoolDistrictDetailContainer = lazyLoadWithFailSafe(() => import('../Modules/SchoolDistrict/SchoolDistrictDetail/SchoolDistrictDetailContainer'));

const CalendarListContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Calendar/CalendarList/CalendarListContainer'));
const CalendarDetailContainer = lazyLoadWithFailSafe(() => import('../Modules/Calendar/CalendarDetail/CalendarDetailContainer'));

const UserListContainer = lazyLoadWithFailSafe(() => import('../Modules/User/UserList/UserListContainer'));
const UserDetailContainer = lazyLoadWithFailSafe(() => import('../Modules/User/UserDetail/UserDetailContainer'));
const ChangePasswordContainer = lazyLoadWithFailSafe(() => import('../Modules/ChangePassword/ChangePasswordContainer'));

const ReportsContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Report/ReportsList/ReportsListContainer'));
const BusRosterContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Report/BusRosterReport/BusRosterReportContainer'));
const StopsReportContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Report/StopsReport/StopsReportContainer'));
const RiderReportContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Report/RiderReport/RiderReportContainer'));
const StudentReportContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Report/StudentReport/StudentReportContainer'));
const InquireByCodeReportContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Report/InquireByCodeReport/InquireByCodeReportContainer'));
const LetterToParentReportContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Report/LetterToParentReport/LetterToParentReportContainer'));
const ManagementActivityReportContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Report/ManagementActivityReport/ManagementActivityReportContainer'));
const SortStudentReportContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Report/SortStudentReport/SortStudentReportContainer'));
const VehicleReportContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Report/VehicleReport/VehicleReportContainer'));

const StudentListContainer = lazyLoadWithFailSafe(() => import('../Modules/Student/StudentList/StudentListContainer'));
const PreRegisterLookup = lazyLoadWithFailSafe(() => import('../Modules/Student/PreRegisterLookup/PreRegisterLookupContainer'));
const SiblingAutoFill = lazyLoadWithFailSafe(() => import('../Modules/Student/SiblingAutoFill/SiblingAutoFillContainer'));
const StudentDetailContainer = lazyLoadWithFailSafe(() => import('../Modules/Student/StudentDetail/StudentDetailContainer'));

const ToolsPageContainer = lazyLoadWithFailSafe(() => import(/* webpackChunkName: "pdfPages" */ '../Modules/Tools/ToolsPageContainer'));
const ToolsForSchoolUser = lazyLoadWithFailSafe(() => import('../Modules/Tools/ToolsForSchoolUser'));

export const SchoolRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/detail`} render={routeParams => <SchoolDetailContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/list`} render={routeParams => <SchoolListContainer {...routeParams} key={routeParams.match.url} />} />
      <Redirect from="*" to="/login" push />
    </Switch>
  );
};

export const SchoolDistrictRoutes = () => {
  const { globalState } = React.useContext(GlobalContext);
  const dispatch = React.useContext(GlobalDispatchContext);
  const { path } = useRouteMatch();
  React.useEffect(() => {
    dispatch({ type: LIST_REQUEST });
    Promise.all([getGradeList(), getAcademicYrList(globalState.userDetails.CompanyId)])
      .then(res => {
        if (res[0].data?.Status) {
          const payload = {
            gradeList: res[0].data?.Data || [],
            academicYrList: res[1].data?.Data || []
          };
          dispatch({ type: LIST_SUCCESS, payload: payload });
        } else {
          dispatch({ type: LIST_FAILED });
          openSnackbar('error', res.data?.Message || 'Failed to Get List !!');
        }
      })
      .catch(e => {
        dispatch({ type: LIST_FAILED });
        openSnackbar('error', e?.message || 'Failed to Get List !!');
      });
  }, [dispatch, globalState.userDetails.CompanyId]);
  return (
    <Switch>
      <Route path={`${path}/detail`} render={routeParams => <SchoolDistrictDetailContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/list`} render={routeParams => <SchoolDistrictListContainer {...routeParams} key={routeParams.match.url} />} />
      <Redirect from="*" to="/login" push />
    </Switch>
  );
};

export const DashBoardRoutes = () => {
  const { path } = useRouteMatch();
  const dispatch = React.useContext(GlobalDispatchContext);
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    dispatch({ type: LIST_REQUEST });
    Promise.all([getSchoolDistrictList()])
      .then(res => {
        // Promise.all([getSchoolDistrictList(), getAcademicYrList(globalState.userDetails.CompanyId)]).then(res => {
        if (res[0].data?.Status) {
          const payload = {
            districtList: res[0].data?.Data || []
            // academicYrList: res[1].data?.Data || []
          };
          dispatch({ type: LIST_SUCCESS, payload: payload });
          setState(true);
        } else {
          dispatch({ type: LIST_FAILED });
          openSnackbar('error', res.data?.Message || 'Failed to Get List !!');
        }
      })
      .catch(e => {
        dispatch({ type: LIST_FAILED });
        openSnackbar('error', e.message || 'Failed to Get List !!');
      });
  }, [dispatch]);
  return (
    <>
      {' '}
      {state && (
        <Switch>
          <Route path={`${path}`} render={routeParams => <DashboardContainer {...routeParams} key={routeParams.match.url} />} />
          <Redirect from="*" to="/login" push />
        </Switch>
      )}
    </>
  );
};

export const UserRoutes = () => {
  const { path } = useRouteMatch();
  const dispatch = React.useContext(GlobalDispatchContext);
  const { globalState } = React.useContext(GlobalContext);
  React.useEffect(() => {
    dispatch({ type: LIST_REQUEST });
    Promise.all([getSchoolList(globalState.userDetails.CompanyYearId), getSchoolDistrictList(), getUserTypeList(globalState.userDetails.UserType)])
      .then(res => {
        if (res[0].data?.Status) {
          const payload = {
            schoolList: res[0].data?.Data || [],
            districtList: res[1].data?.Data || [],
            userTypeList: res[2].data?.Data || []
          };
          dispatch({ type: LIST_SUCCESS, payload: payload });
        } else {
          dispatch({ type: LIST_FAILED });
          openSnackbar('error', res.data?.Message || 'Failed to Get List !!');
        }
      })
      .catch(e => {
        dispatch({ type: LIST_FAILED });
        openSnackbar('error', e?.message || 'Failed to Get List !!');
      });
  }, [dispatch, globalState.userDetails.CompanyId, globalState.userDetails.CompanyYearId, globalState.userDetails.UserType]);
  return (
    <Switch>
      <Route path={`${path}/detail`} render={routeParams => <UserDetailContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/profile`} render={routeParams => <UserDetailContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/changePassword`} render={routeParams => <ChangePasswordContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/list`} render={routeParams => <UserListContainer {...routeParams} key={routeParams.match.url} />} />
      <Redirect from="*" to="/login" push />
    </Switch>
  );
};

export const CalendarRoutes = () => {
  const { path } = useRouteMatch();
  const dispatch = React.useContext(GlobalDispatchContext);
  const { globalState } = React.useContext(GlobalContext);
  const [state, setState] = React.useState(false);
  React.useEffect(() => {
    dispatch({ type: LIST_REQUEST });
    Promise.all([getSchoolList(globalState.userDetails.CompanyYearId), getSchoolDistrictList(), getAcademicYrList(globalState.userDetails.CompanyId)])
      .then(res => {
        if (res[0].data?.Status) {
          const payload = {
            schoolList: res[0].data?.Data || [],
            districtList: res[1].data?.Data || [],
            academicYrList: res[2].data?.Data || []
          };
          dispatch({ type: LIST_SUCCESS, payload: payload });
          setState(true);
        } else {
          dispatch({ type: LIST_FAILED });
          openSnackbar('error', res.data?.Message || 'Failed to Get List !!');
        }
      })
      .catch(e => {
        dispatch({ type: LIST_FAILED });
        openSnackbar('error', e?.message || 'Failed to Get List !!');
      });
  }, [dispatch, globalState.userDetails.CompanyId, globalState.userDetails.CompanyYearId, globalState.userDetails.UserType]);
  return (
    <>
      {state && (
        <Switch>
          <Route path={`${path}/detail`} render={routeParams => <CalendarDetailContainer {...routeParams} key={routeParams.match.url} />} />
          <Route path={`${path}/list`} render={routeParams => <CalendarListContainer {...routeParams} key={routeParams.match.url} />} />
          <Redirect from="*" to="/login" push />
        </Switch>
      )}
    </>
  );
};

export const ReportRoutes = () => {
  const { globalState } = React.useContext(GlobalContext);
  const dispatch = React.useContext(GlobalDispatchContext);
  const { path } = useRouteMatch();
  React.useEffect(() => {
    dispatch({ type: LIST_REQUEST });
    Promise.all([getGradeList(), getAcademicYrList(globalState.userDetails.CompanyId), getSchoolList(globalState.userDetails.CompanyYearId), getSchoolDistrictList()])
      .then(res => {
        if (res[0].data?.Status) {
          const payload = {
            gradeList: res[0].data?.Data || [],
            academicYrList: res[1].data?.Data || [],
            schoolList: res[2].data?.Data || [],
            districtList: res[3].data?.Data || []
          };
          dispatch({ type: LIST_SUCCESS, payload: payload });
        } else {
          dispatch({ type: LIST_FAILED });
          openSnackbar('error', res.message || 'Failed to Get List !!');
        }
      })
      .catch(e => {
        dispatch({ type: LIST_FAILED });
        openSnackbar('error', e?.message || 'Failed to Get List !!');
      });
  }, [dispatch, globalState.userDetails.CompanyId, globalState.userDetails.CompanyYearId]);

  return (
    <Switch>
      <Route path={`${path}/board`} render={routeParams => <ReportsContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/busRoster`} render={routeParams => <BusRosterContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/stops`} render={routeParams => <StopsReportContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/rider`} render={routeParams => <RiderReportContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/student`} render={routeParams => <StudentReportContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/inquirecode`} render={routeParams => <InquireByCodeReportContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/letter`} render={routeParams => <LetterToParentReportContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/activity`} render={routeParams => <ManagementActivityReportContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/sortstudent`} render={routeParams => <SortStudentReportContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/vehicle`} render={routeParams => <VehicleReportContainer {...routeParams} key={routeParams.match.url} />} />
      <Redirect from="*" to="/login" push />
    </Switch>
  );
};

export const StudentRoutes = () => {
  const { globalState } = React.useContext(GlobalContext);
  const dispatch = React.useContext(GlobalDispatchContext);
  const { path } = useRouteMatch();
  React.useEffect(() => {
    dispatch({ type: LIST_REQUEST });
    Promise.all([getGradeList(), getAcademicYrList(globalState.userDetails.CompanyId), getSchoolList(globalState.userDetails.CompanyYearId)])
      .then(res => {
        if (res[0].data?.Status) {
          const payload = {
            gradeList: res[0].data?.Data || [],
            academicYrList: res[1].data?.Data || [],
            schoolList: res[2].data?.Data || []
          };
          dispatch({ type: LIST_SUCCESS, payload: payload });
        } else {
          dispatch({ type: LIST_FAILED });
          openSnackbar('error', res.message || 'Failed to Get List !!');
        }
      })
      .catch(e => {
        dispatch({ type: LIST_FAILED });
        openSnackbar('error', e?.message || 'Failed to Get List !!');
      });
  }, [dispatch, globalState.userDetails.CompanyId, globalState.userDetails.CompanyYearId]);

  return (
    <Switch>
      <Route path={`${path}/list`} render={routeParams => <StudentListContainer {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/preregister`} render={routeParams => <PreRegisterLookup {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/siblingautofill`} render={routeParams => <SiblingAutoFill {...routeParams} key={routeParams.match.url} />} />
      <Route path={`${path}/detail`} render={routeParams => <StudentDetailContainer {...routeParams} key={routeParams.match.url} />} />
      <Redirect from="*" to="/login" push />
    </Switch>
  );
};

export const ToolsRoutes = () => {
  const { path } = useRouteMatch();
  const { globalState } = React.useContext(GlobalContext);
  return (
    <Switch>
      {[3, 6].includes(globalState.userDetails.UserType) || globalState.isInActiveUser ? (
        <Route path={`${path}`} render={routeParams => <ToolsForSchoolUser {...routeParams} key={routeParams.match.url} />} />
      ) : (
        <Route path={`${path}`} render={routeParams => <ToolsPageContainer {...routeParams} key={routeParams.match.url} />} />
      )}
      <Redirect from="*" to="/login" push />
    </Switch>
  );
};
