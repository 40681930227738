import React from 'react';
import ams_logo from './../../Assets/images/ams_img.png';
import ams_logo_mobile from './../../Assets/images/ams_img_mobile_logo.png';

const Footer = () => (
  <>
    {' '}
    <footer>
      <div className="ams_logo">
        <img className="logoimg notMobile" src={ams_logo} alt="" />
        <img className="logoimg for_mobile_tab" src={ams_logo_mobile} alt="" />
      </div>
      <div className="footer_components">
        <div className="version_text">
          Version: <span>BTVXXX</span>
        </div>
        <div className="copyright">© 2020 Advanced Management Software, LLC. All right reserved</div>
      </div>
    </footer>{' '}
  </>
);
export default Footer;
