import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const LeftMenu = ({ componentData, UserType, isInActiveUser }) => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const isSchoolUser = UserType === 6 || isInActiveUser;
  const navigateMenu = menu => {
    setOpen(false);
    switch (menu) {
      case 'dashboard':
        history.push('/dashboard');
        break;
      case 'student':
        history.push('/student/list');
        break;
      case 'school':
        history.push('/school/list');
        break;
      case 'district':
        if ([2, 4].includes(UserType)) {
          history.push('/district/detail');
        } else {
          history.push('/district/list');
        }
        break;
      case 'calendar':
        history.push('/calendar/list');
        break;
      case 'vehicle':
        history.push('/vehicle/list');
        break;
      case 'staff':
        history.push('/staff/list');
        break;
      case 'mileage':
        history.push('/mileage');
        break;
      case 'run':
        history.push('/runs/list');
        break;
      case 'report':
        history.push('/report/board');
        break;
      case 'tools':
        history.push('/tools/board');
        break;
      case 'house':
        history.push('/house/list');
        break;
      case 'stop':
        history.push('/stop/list');
        break;
      case 'movegroups':
        history.push('/movegroups');
        break;
      default:
        break;
    }
  };
  return (
    <>
      {!['/dashboard', '/login'].includes(location.pathname) && (
        <div className={`left_menu_block ${open ? 'active' : ''}`} tabIndex="0" onBlur={() => setOpen(false)} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
          <div className="menuSwitch">
            <span className="openSwitch" onClick={() => setOpen(state => !state)}></span>
            <span className="closeSwitch" onClick={() => setOpen(state => !state)}></span>
          </div>
          <span className={`menu-item ${componentData === 'dashboard' ? 'active' : ''}`} onClick={() => navigateMenu('dashboard')}>
            <span className={`menu-icon-item dashboard-menu`}></span>
            <span className="menu_title">Dashboard</span>
          </span>
          <span className={`menu-item runmenu ${componentData === 'runs' ? 'active' : ''}`} onClick={() => navigateMenu('run')}>
            <span className={`menu-icon-item  run-menu`}> </span>
            <span className="menu_title">Bus Runs</span>
          </span>
          {!isSchoolUser && (
            <span className={`menu-item districtmenu ${componentData.startsWith('district') ? 'active' : ''}`} onClick={() => navigateMenu('district')}>
              <span className={`menu-icon-item  schooldistrict-menu `}> </span>
              <span className="menu_title">School District</span>
            </span>
          )}
          <span className={`menu-item schoolmenu ${componentData.startsWith('school') ? 'active' : ''}`} onClick={() => navigateMenu('school')}>
            <span className={`menu-icon-item  school-menu `}> </span>
            <span className="menu_title">School</span>
          </span>
          <span className={`menu-item studentmenu ${componentData.startsWith('student') ? 'active' : ''}`} onClick={() => navigateMenu('student')}>
            <span className={`menu-icon-item  student-menu `}> </span>
            <span className="menu_title">Student</span>
          </span>
          {!isSchoolUser && (
            <>
              <span className={`menu-item staffmenu ${componentData === 'staff' ? 'active' : ''}`} onClick={() => navigateMenu('staff')}>
                <span className={`menu-icon-item  staff-menu `}> </span>
                <span className="menu_title">Staff</span>
              </span>
              <span className={`menu-item vehiclemenu ${componentData === 'vehicle' ? 'active' : ''}`} onClick={() => navigateMenu('vehicle')}>
                <span className={`menu-icon-item vehicle-menu`}> </span>
                <span className="menu_title">Vehicle</span>
              </span>
              <span className={`menu-item calendarmenu ${componentData.startsWith('calendar') ? 'active' : ''}`} onClick={() => navigateMenu('calendar')}>
                <span className={`menu-icon-item  calendar-menu `}> </span>
                <span className="menu_title">Calendar</span>
              </span>
              <span className={`menu-item housesmenu ${componentData === 'house' ? 'active' : ''}`} onClick={() => navigateMenu('house')}>
                <span className={`menu-icon-item  houses-menu `}> </span>
                <span className="menu_title">Houses</span>
              </span>
              <span className={`menu-item stopmenu ${componentData === 'stop' ? 'active' : ''}`} onClick={() => navigateMenu('stop')}>
                <span className={`menu-icon-item  stop-menu `}> </span>
                <span className="menu_title">Stop</span>
              </span>
            </>
          )}
          <span className={`menu-item reportmenu ${componentData === 'report' ? 'active' : ''}`} onClick={() => navigateMenu('report')}>
            <span className={`menu-icon-item  report-menu `}> </span>
            <span className="menu_title">Report</span>
          </span>
          <span className={`menu-item toolsmenu ${componentData === 'tools' ? 'active' : ''}`} onClick={() => navigateMenu('tools')}>
            <span className={`menu-icon-item  tools-menu `}> </span>
            <span className="menu_title">Tools</span>
          </span>
          {!isSchoolUser && (
            <>
              <span className={`menu-item mileagemenu ${componentData === 'mileage' ? 'active' : ''}`} onClick={() => navigateMenu('mileage')}>
                <span className={`menu-icon-item  mileage-menu `}> </span>
                <span className="menu_title">Mileage</span>
              </span>
              <span className={`menu-item movegroupmenu ${componentData === 'movegroup' ? 'active' : ''}`} onClick={() => navigateMenu('movegroups')}>
                <span className={`menu-icon-item  movegroup-menu `}> </span>
                <span className="menu_title">Move Group</span>
              </span>
            </>
          )}
        </div>
      )}
    </>
  );
};
LeftMenu.propTypes = {
  componentData: PropTypes.string,
  UserType: PropTypes.number,
  isInActiveUser: PropTypes.bool
};
export default LeftMenu;
