import React from 'react';

export const reloadAppOnLazyFail = (fn, retriesLeft = 3, interval = 1000) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            console.log('max try reached', error);
            window.location.reload();
            // reject(error);
            return;
          }
          // Passing on "reject" is the important part
          reloadAppOnLazyFail(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

export const lazyLoadWithFailSafe = fn => React.lazy(() => reloadAppOnLazyFail(fn));

const LazyLoader = {
  reloadAppOnLazyFail,
  lazyLoadWithFailSafe
};

export default LazyLoader;
