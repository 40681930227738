import React from 'react';
import { GlobalContext } from '../../Services/ContextAPI';
import logo from './../../Assets/images/logo.png';
import { useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { clearAndLogout, getNotificationList, getRole } from '../../Services/CommonServices';
import NotificationPanel from './NotificationPanel';
import { getInitials } from '../../Utilities/Utils';

const Header = () => {
  const history = useHistory();
  const { globalState } = React.useContext(GlobalContext);
  const logout = () => {
    clearAndLogout();
  };

  const navigateMenu = menu => {
    switch (menu) {
      case 'dashboard':
        history.push('/dashboard');
        break;
      case 'notification':
        history.push('/notification');
        break;
      case 'student':
        history.push('/student/list');
        break;
      case 'schooldistrict':
        history.push('/district/list');
        break;
      case 'user':
        history.push('/user/list');
        break;
      case 'userdetail':
        history.push('/user/profile');
        break;
      case 'changepassword':
        history.push('/user/changepassword');
        break;
      default:
        break;
    }
  };

  return (
    <>
      {' '}
      <header>
        <div id="logo" className="pageLogo" onClick={() => navigateMenu('dashboard')}>
          <img src={logo} alt="" />{' '}
        </div>
        <div className="header_nav">
          {globalState.currentComponent === 'dashboard' && (
            <div className="notification-section-panel">{[2, 4].includes(globalState.userDetails.UserType) && <NotificationPanel notifications={getNotificationList(globalState.listDataState?.notifications || {})} />}</div>
          )}
          <div className="user_profile_menu">
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <div className="user_profile_wrap">
                  <span className="profile_img">
                    {getInitials(globalState.userDetails.LastName)}
                    {getInitials(globalState.userDetails.FirstName)}{' '}
                  </span>
                  <div className="profile_wrap">
                    <span className="profile_name">
                      <span>{`${globalState.userDetails.LastName || ''} ${globalState.userDetails.FirstName} ${globalState.userDetails.MiddleName || ''} `}</span> <span className="caret"></span>{' '}
                    </span>
                    <span className="profile_role">{getRole(globalState.userDetails.UserType)}</span>
                  </div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item className="menu_condition">
                  {' '}
                  <span className="last_seen_text">
                    {' '}
                    Last Seen - <span>10/20/2020</span>
                  </span>
                </Dropdown.Item>
                {[3, 6, 2, 4].includes(globalState.userDetails.UserType) && <Dropdown.Item onClick={() => navigateMenu('userdetail')}>Profile</Dropdown.Item>}
                {[1, 5].includes(globalState.userDetails.UserType) && <Dropdown.Item onClick={() => navigateMenu('notification')}>Manage Notifications</Dropdown.Item>}
                {![3, 6].includes(globalState.userDetails.UserType) && !globalState.isInActiveUser && <Dropdown.Item onClick={() => navigateMenu('user')}>Manage Users</Dropdown.Item>}
                {[1, 5].includes(globalState.userDetails.UserType) && <Dropdown.Item onClick={() => navigateMenu('schooldistrict')}>Manage School District</Dropdown.Item>}
                {[2, 4].includes(globalState.userDetails.UserType) && <Dropdown.Item onClick={() => navigateMenu('schooldistrict')}>School District</Dropdown.Item>}

                <Dropdown.Item onClick={() => navigateMenu('changepassword')}>Change Password</Dropdown.Item>
                <Dropdown.Item onClick={logout}>Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
