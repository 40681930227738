import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { GlobalContext } from '../Services/ContextAPI';

const RootRouteGuard = ({ xComponent: Component, ...xProps }) => {
  const { globalState } = React.useContext(GlobalContext);
  return (
    <Route
      {...xProps}
      render={routeParams => {
        const pathName = routeParams.match.path;
        if (globalState.isLoggedIn) {
          if (pathName === '/login') {
            return <Redirect to="/dashboard" />;
          }
          return <Component {...routeParams} key={routeParams.match.url} />;
        }
        if (pathName === '/login') {
          return <Component {...routeParams} key={routeParams.match.url} />;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};
RootRouteGuard.propTypes = {
  xComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export default RootRouteGuard;
