export const studentAPIEndpoints = {
  list: () => `/Student/GetStudents`,
  create: () => `/Student/AddStudent`,
  getStudent: id => `/Student/GetStudentDetails?studentId=${id}`,
  update: () => `/Student/SaveStudentDetails`,
  delete: id => `/Student/DeleteStudent?studentId=${id}`,
  getMachineId: (lname, compYrId) => `/Student/GenerateMachineId?name=${lname}&companyyearId=${compYrId}`,
  addSibling: id => `/Student/AddSibling?siblingId=${id}`,
  copySiblingData: (id1, id2) => `/Student/CopyStudentContactInfo?sourceId=${id1}&targetId=${id2}`,
  getSiblingStudents: id => `/Student/StudentListSiblingAutofill?companyYearId=${id}`,
  getSiblingDetails: id => `/Student/StudentDetailsSiblingAutofill?studentId=${id}`,
  addWithTargetAddress: () => `/Student/AddStudentWithTargetAddress`,
  listPreregister: () => `/Student/GetPreRegisterBusDetails`
};
export const userAPIEndpoints = {
  login: () => `/User/Authenticate`,
  create: () => `/User/SaveUsers`,
  list: () => `/User/GetUsers`,
  getUser: id => `/User/GetUserById?userId=${id}`,
  resetPwd: id => `/User/ResetPassword?email=${id}`,
  changePassword: () => `/User/ChangePassword`,
  getAllowedDistricts: id => `/Shared/GetCompanyUserList?userTypeId=${id}`
};

export const districtAPIEndpoints = {
  list: () => `/Company/GetSchoolDistricts`,
  create: () => `/Company/SaveSchoolDistrict`,
  getDetail: id => `/Company/GetSchoolDistrictDetails?companyId=${id}`,
  delete: id => `/Company/DeleteSchoolDistrict?companyId=${id}`
};

export const schoolAPIEndpoints = {
  list: () => `/Building/GetBuildings`,
  create: () => `/Building/SaveBuilding`,
  getDetail: id => `/Building/GetBuildingDetails?buildingId=${id}`,
  delete: id => `/Building/DeleteBuilding?buildingId=${id}`,
  getProgramDetail: id => `/Building/GetProgramAndContacts?buildingId=${id}`,
  saveProgramContacts: () => `/Building/SaveProgramContacts`,
  deleteProgram: id => `/Building/DeleteProgram?buildingId=${id}`
};

export const miscAPIEndpoints = {
  gradeList: () => `/Shared/GetGradeList`,
  schoolList: id => `/Shared/GetSchoolList?companyYearId=${id}`,
  schoolDistrictList: () => `/Shared/GetCompanyList`,
  academicYrList: id => `/Shared/GetAcademicYearList?companyId=${id}`,
  userTypeList: id => `/Shared/GetUserTypeList?usertypeId=${id}`,
  schoolYrList: () => `/Shared/GetSchoolYearList`,
  contractorNameList: (id, isreport) => (isreport ? `/Shared/GetContractorNameList?companyYearId=${id}&textFieldType=1` : `/Shared/GetContractorNameList?companyYearId=${id}`),
  getVehicleList: (id, isreport) => (isreport ? `/Shared/GetVehicleNumberList?companyYearId=${id}&textFieldType=1` : `/Shared/GetVehicleNumberList?companyYearId=${id}`),
  getDriverList: id => `/Shared/GetDriverList?companyYearId=${id}`,
  getSeatList: () => '/Shared/GetSeatList',
  getRunsList: id => `/Shared/GetRunsList?companyYearId=${id}`,
  stopTypeList: () => `/Shared/GetStopTypeList`,
  resetStopCount: (companyYearId) => `/BusRuns/UpdateAllStopsCount?companyYearId=${companyYearId}`,
  getNotifications: (companyId, companyYearId) => `/Shared/GetLicenceExpireDetails?companyId=${companyId}&companyYearId=${companyYearId}`
};

export const calendarAPIEndpoints = {
  getCalendar: id => `/GetCalendarDetails?buildingId=${id}`,
  delete: () => `/DeleteCalendars`,
  copy: (id1, id2) => `/CopyCalendar?copyFromCalendarId=${id1}&copyToCalendarId=${id2}`,
  save: () => `/SaveCalendars`,
  markchart: () => `/MarkUnMarkSchoolsAsCharter`,
  markpub: () => `/MarkUnMarkSchoolsAsNonPub`,
  getSchoolsByOpenDt: (id, dt) => `/GetSchoolsOpenOnDate?companyYearId=${id}&selectedDate=${dt}`,
  getReportForSchoolYear: id => `/GetCalendarReportForSchoolYear?buildingId=${id}`,
  getReportForSingleMonth: (buildingId, companyYearId, month) => `/GetCalendarReportForSingleMonth?buildingId=${buildingId}&companyYearId=${companyYearId}&month=${month}`,
  getReportForAllSchools: companyYearId => `/GetCalendarReportForAllSchools?companyYearId=${companyYearId}`,
  getReportForActiveDaysTotalOnly: companyYearId => `/GetCalendarReportForActiveDaysTotalOnly?companyYearId=${companyYearId}`,
  getReportForAllClosedSchoolsByMonth: () => `/GetCalendarReportForAllClosedSchoolsByMonth`
};

export const vehicleAPIEndpoints = {
  list: () => `/Vehicle/GetVehicles`,
  create: () => `/Vehicle/SaveVehicle`,
  getDetail: id => `/Vehicle/GetVehicleDetails?vehicleId=${id}`,
  delete: id => `/Vehicle/DeleteVehicle?vehicleId=${id}`
};

export const contractAPIEndpoints = {
  listContractor: id => `/Vehicle/GetContractorList?companyYearId=${id}`,
  list: () => `/Vehicle/GetContracts`,
  create: () => `/Vehicle/SaveContract`,
  getDetail: id => `/Vehicle/GetContractDetails?contractId=${id}`,
  delete: id => `/Vehicle/DeleteContract?contractId=${id}`
};

export const staffAPIEndpoints = {
  listContractor: id => `/Vehicle/GetContractorList?companyYearId=${id}`,
  list: () => `/Staff/GetDrivers`,
  create: () => `/Staff/SaveStaffDetails`,
  getDetail: id => `/Staff/GetDriverDetails?staffId=${id}`,
  delete: id => `/Staff/DeleteStaff?staffId=${id}`
};

export const busRunsAPIEndpoints = {
  listContractor: id => `/Vehicle/GetContractorList?companyYearId=${id}`,
  list: () => `/BusRuns/GetRuns`,
  create: () => `/BusRuns/SaveRun`,
  getDetail: id => `/BusRuns/GetRunDetails?runId=${id}`,
  deleteRun: id => `/BusRuns/DeleteRun?runId=${id}`,
  deleteRider: id => `/BusRuns/DeleteRiderAssignment?riderId=${id}`,
  checkRunExist: () => `/BusRuns/CheckRunNumberExists`,
  copyRun: () => `/BusRuns/CopyRun`,
  getStopList: () => `/BusRuns/GetStops`,
  getRiderList: () => `/BusRuns/GetRiders`,
  getRiderAtStop: () => `/BusRuns/GetRidersAtStop`,
  getStudent: id => `/Student/GetStudentDetails?studentId=${id}`,
  getRiderAssignment: id => `/BusRuns/GetRiderAssignment?riderId=${id}`,
  getStopDetail: id => `/BusRuns/GetStopDetails?stopId=${id}`,
  saveStop: () => `/BusRuns/SaveStopDetails`,
  getRunAssignment: id => `/BusRuns/GetRunDetails?runId=${id}`,
  saveRiderAssignment: () => '/BusRuns/SaveRiderAssignment',
  contractorNameList: id => `/Shared/GetContractorNameList?companyYearId=${id}`,
  getNeighborsBusAssignments: (id, type) => `/BusRuns/GetNeighborsBusAssignments?studentId=${id}&addressType=${type}`,
  schoolList: id => `/Shared/GetSchoolList?companyYearId=${id}&IsProgramAndSchool=1`,
  setDoorToDoorDelivery: (runId, student_id, type) => `/BusRuns/SetupDoorToDoorDelivery?studentId=${student_id}&addressType=${type}&runId=${runId}`,
  getProgramDetail: id => `/Building/GetProgramAndContacts?buildingId=${id}`,
  deleteStop: (id, IsContinue) => `/BusRuns/DeleteStop?stopId=${id}&IsContinue=${IsContinue}`,
  getSeatList: () => '/Shared/GetSeatList',
  resequenceStops: () => `/BusRuns/ResequenceStops`,
  resequenceAllStops: () => `/BusRuns/ResequenceAllRunsStops`,
  addStaticStops: () => `/BusRuns/AddStop`,
  markHazardous: () => `/MapView/MarkUnmarkRidersHazardous`,
  getStopListView: () => `/MapView/GetStopViewList`,
  moveGroups: () => `/BusRuns/MoveGroups`
};

export const toolsAPIEndpoints = {
  saveImportSettings: () => `/Tools/SaveImportSettings`,
  getImportSettings: id => `/Tools/GetImportSettings?companyId=${id}`,
  getImportColumns: () => `/Tools/GetImportColumns`,
  getManualImportSettings: id => `/Tools/GetManualImportSettings?companyId=${id}`,
  updateManualImportSettings: () => `/Tools/InsertUpdateDeleteManualImportSettings`,
  getGradutionPaths: () => `/Tools/GetGraduationPaths`,
  getGradutionPathDetails: id => `/Tools/GetGraduationPathDetails?graduationPathId=${id}`,
  saveGraduationPath: () => `/Tools/SaveGraduationPath`,
  deleteGraduationPath: id => `/Tools/DeleteGraduationPath?graduationPathId=${id}`,
  PerformYearEndProcess: () => `/Tools/PerformYearEndProcess`,
  downloadImportFile: id => `/Tools/GetImportTemplateforDownload?companyId=${id}`,
  getRolloverStatus: id => `/Tools/GetRolloverStatus?companyYearId=${id}`,
  downloadYrEndReport: id => `/Tools/GetYearEndReportForDownload?companyYearId=${id}`,
  uploadImportFile: () => `/Tools/ManualImportStudents`,
  getExportSettings: id => `/Tools/GetExportSettings?companyId=${id}`,
  saveExportSettings: () => `/Tools/SaveExportSettings`,
  exportBusAssignment: (companyid, companyYrId) => `/Tools/ExportBusAssignments?companyId=${companyid}&companyYearId=${companyYrId}`,
  exportCsvFile: (companyYrId, fileName) => `/Tools/ExportFile?companyYearId=${companyYrId}&fileName=${fileName}`,
  importCsvFile: () => `/Tools/ImportFile`,
  createAllCsvFile: companyYrId => `/Tools/ExportZipFile?companyYearId=${companyYrId}`,
  manualBackup: id => `/Tools/ManualBackup?companyYearId=${id}`,
  restoreData: body => `/Tools/RestoreData?companyYearId=${body.CompanyYearId}&backupDateTobeRestored=${body.BackupDateTobeRestored}`,
  createFTP: id => `/Tools/CreateFTPPath?companyId=${id}`,
  stopMerge: () => `/Tools/MergeStops`,
  previewImport: () => `/Tools/PreviewStudentImport`,
  getStopsForGeocode: () => `/Tools/GetStopsForGeocode`,
  saveStopswithGeocode: () => `/Tools/SaveStopsWithGeocode`,
  performBatchGeocoding: () => `/Tools/BatchGeoCoding`
};

export const mileageAPIEndpoints = {
  getMileageEntries: () => `/Mileage/GetMileageEntries`,
  getMileageEntryDetails: id => `/Mileage/GetMileageEntryDetails?mileageId=${id}`,
  saveMileageEntry: () => `/Mileage/SaveMileageEntry`,
  deleteMileageEntry: id => `/Mileage/DeleteMileageEntry?mileageId=${id}`
};
const APIKEY = () => {
  const API = 'AIzaSyDVIgMIxpg_VORAhsudj69Pfu2s-88GyEg';
  return API;
};
export const mapAPIEndpoints = {
  //GoogleAPIs
  reverseGeocode: latlng => `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${APIKEY()}`,
  geocoder: address => `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${APIKEY()}`
};

export const reportAPIEndpoints = {
  riderList: () => `/Report/GetAlphabeticListOfRiders`,
  studentList: () => `/Report/GetAlphabeticListOfStudents`,
  inquireStudentList: () => `/Report/GetAlphabeticListOfStudentsWithEWRCodes`,
  rosterList: () => `/Report/GetBusRosters`,
  getSortedStudentLocations: () => `/Report/GetSortedStudentLocations`,
  getStopReportList: () => `/Report/GetStopList`,
  getVehicleAuditCheckReport: () => `/Report/GetVehicleAuditCheckReport`,
  getManagementActivityReport: () => `/Report/GetManagementActivityReport`,
  getVehicleSummaryReport: () => `/Report/GetVehicleSummaryReport`,
  getVehicleEOYReport: () => `/Report/GetVehicleEOYReport`,
  getLetterToParents: () => `/Report/GetLetterToParentsReport`,
  createMailMerge: () => `/Report/GetLetterToParentsMailMergeReport`,
  getDistrictTransportationCostReport: () => `/Report/GetDistrictTransportationCostReport`,
  getCostAnalysisReport: (companyYrId, fileName) => `/Report/GetCostAnalysisReport?companyYearId=${companyYrId}&fileName=${fileName}`
};

export const adminAPIEndpoints = {
  getNotifications: () => `/AdminTools/GetNotifications`,
  saveNotification: () => `/AdminTools/SaveNotification`,
  deleteNotification: id => `/AdminTools/DeleteNotification?notificationId=${id}`,
  getNotificationDetails: id => `/AdminTools/GetNotificationDetails?notificationId=${id}`
};
