// Login
export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SUCCESS_USER_DETAILS = 'LOGIN_SUCCESS_USER_DETAILS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';

export const API = 'API';
export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAILED = 'API_FAILED';

export const STUDENT = 'STUDENT';
export const STUDENT_REQUEST = 'STUDENT_REQUEST';
export const STUDENT_SUCCESS = 'STUDENT_SUCCESS';
export const STUDENT_FAILED = 'STUDENT_FAILED';

export const LIST = 'LIST';
export const LIST_REQUEST = 'LIST_REQUEST';
export const LIST_SUCCESS = 'LIST_SUCCESS';
export const LIST_FAILED = 'LIST_FAILED';

export const USER = 'USER';
export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILED = 'USER_FAILED';

export const SCHOOL = 'SCHOOL';
export const SCHOOL_REQUEST = 'SCHOOL_REQUEST';
export const SCHOOL_SUCCESS = 'SCHOOL_SUCCESS';
export const SCHOOL_FAILED = 'SCHOOL_FAILED';

export const DISTRICT = 'DISTRICT';
export const DISTRICT_REQUEST = 'DISTRICT_REQUEST';
export const DISTRICT_SUCCESS = 'DISTRICT_SUCCESS';
export const DISTRICT_FAILED = 'DISTRICT_FAILED';

export const CALENDAR = 'CALENDAR';
export const CALENDAR_REQUEST = 'CALENDAR_REQUEST';
export const CALENDAR_SUCCESS = 'CALENDAR_SUCCESS';
export const CALENDAR_FAILED = 'CALENDAR_FAILED';

export const VEHICLE = 'VEHICLE';
export const VEHICLE_REQUEST = 'VEHICLE_REQUEST';
export const VEHICLE_SUCCESS = 'VEHICLE_SUCCESS';
export const VEHICLE_FAILED = 'VEHICLE_FAILED';

export const CONTRACT = 'CONTRACT';
export const CONTRACT_REQUEST = 'CONTRACT_REQUEST';
export const CONTRACT_SUCCESS = 'CONTRACT_SUCCESS';
export const CONTRACT_FAILED = 'CONTRACT_FAILED';

export const STAFF = 'STAFF';
export const STAFF_REQUEST = 'STAFF_REQUEST';
export const STAFF_SUCCESS = 'STAFF_SUCCESS';
export const STAFF_FAILED = 'STAFF_FAILED';

export const RUNS = 'RUNS';
export const RUNS_REQUEST = 'RUNS_REQUEST';
export const RUNS_SUCCESS = 'RUNS_SUCCESS';
export const RUNS_FAILED = 'RUNS_FAILED';

export const PROGRAM = 'PROGRAM';
export const PROGRAM_REQUEST = 'PROGRAM_REQUEST';
export const PROGRAM_SUCCESS = 'PROGRAM_SUCCESS';
export const PROGRAM_FAILED = 'PROGRAM_FAILED';

export const MILEAGE = 'MILEAGE';
export const MILEAGE_REQUEST = 'MILEAGE_REQUEST';
export const MILEAGE_SUCCESS = 'MILEAGE_SUCCESS';
export const MILEAGE_FAILED = 'MILEAGE_FAILED';

export const REPORT = 'REPORT';
export const REPORT_REQUEST = 'REPORT_REQUEST';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_FAILED = 'REPORT_FAILED';

export const TOOLS = 'TOOLS';
export const TOOLS_REQUEST = 'TOOLS_REQUEST';
export const TOOLS_SUCCESS = 'TOOLS_SUCCESS';
export const TOOLS_FAILED = 'TOOLS_FAILED';

export const selectorList = ['API', 'LOGIN', 'GET', 'STUDENT', 'LIST', 'USER', 'SCHOOL', 'DISTRICT', 'CALENDAR', 'VEHICLE', 'CONTRACT', 'STAFF', 'RUNS', 'PROGRAM', 'MILEAGE', 'REPORT', 'TOOLS'];

export const CURRENT_COMPONENT = 'CURRENT_COMPONENT';
export const COMPONENT_DATA = 'COMPONENT_DATA';
